import {
    SET_CONFIGURATIONS,
    UPDATE_CONFIGURATION,
    REMOVE_CONFIGURATION,
    SET_ACTIVE_CONFIGURATIONS,
    UPDATE_ACTIVE_CONFIGURATION,
    REMOVE_ACTIVE_CONFIGURATION,
} from './configurationConstants'
import { produce } from 'immer'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONFIGURATIONS:
            const { configurations } = action
            draft['all'] =  configurations
            break
        
        case UPDATE_CONFIGURATION:
            const { configurationId, data } = action
            draft['all'][configurationId] = data
            break

        case SET_ACTIVE_CONFIGURATIONS:
            const { activeConfigurations } = action
            draft['active'] =  activeConfigurations
            break
        
        case UPDATE_ACTIVE_CONFIGURATION:
            const { activeConfigurationId, activeData } = action
            draft['active'][activeConfigurationId] = activeData
            break

        case REMOVE_ACTIVE_CONFIGURATION:
            const { removeConfigurationId } = action
            delete draft['active'][removeConfigurationId]
            break
        
        case REMOVE_CONFIGURATION:
            const { delConfigId } = action
            delete draft['all'][delConfigId]
            break
    
        default:
            break
        }

    return draft
})