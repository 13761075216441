import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Select, Spin, Table, Input, Button, Typography, Divider, Checkbox, Modal, Popconfirm} from 'antd'
import Highlighter from 'react-highlight-words';
import _ from 'lodash'
import {CloseOutlined, SearchOutlined, UserOutlined} from "@ant-design/icons";

import {setUsers, getAllUsers, updateUserRequest, deleteUserRequest} from '../features/users/usersActions'
import {getAllGroups, addUserToGroup, removeUserFromGroup} from '../features/groups/groupsActions'

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            visible: false,
            modal: {
                title: null,
                action: null,
                arg_one: null,
                arg_two: null,
                message: null,
                mouseX: null,
                mouseY: null,
            }
        };

        this.columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                disabled: false,
                sorter: (a, b) => this.sorterHelper(a.name, b.name),
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                disabled: false,
                sorter: (a, b) => this.sorterHelper(a.email, b.email),
                ...this.getColumnSearchProps('email'),
            },
            {
                title: 'Admin',
                key: 'admin',
                disabled: false,
                render: (text, record) => <Popconfirm title='Toggle admin?'
                                                      onConfirm={() => this.handleRoleChange(record)}><Checkbox
                    checked={record.admin}/></Popconfirm>,
                filters: [
                    {
                        text: 'Admin',
                        value: true,
                    },
                    {
                        text: 'Researcher',
                        value: false,
                    },
                ],
                onFilter: (value, record) => {
                    return record.admin === value
                },
            },
            {
                title: 'Groups',
                key: 'groups',
                disabled: true,
                render: (text, record) => this.renderGroups(record),
            },
            {
                title: 'Active',
                key: 'active',
                render: (text, record) => <Popconfirm title='Toggle active?'
                                                      onConfirm={() => this.onCheckChange(record)}><Checkbox
                    checked={record.active}/></Popconfirm>,
                disabled: false,
                filters: [
                    {
                        text: 'Active',
                        value: true,
                    },
                    {
                        text: 'Inactive',
                        value: false,
                    },
                ],
                onFilter: (value, record) => {
                    return record.active === value
                },
            },
            {
                title: 'Delete',
                key: 'delete',
                render: (text, record) => <Popconfirm title='Are you sure?'
                                                      onConfirm={() => this.props.deleteUserRequest(this.props.token, record.id)}>
                    <Button style={{marginLeft: 10}} icon={<CloseOutlined />} size='small' type='danger' shape='circle'></Button>
                </Popconfirm>
            },
        ]
    }

    renderGroups = (record) => {
        const {Option} = Select
        const options = []

        if (this.props.groups) {
            let sortedActiveGroups = []
            if (!_.isEmpty(this.props.groups.active)) {
                sortedActiveGroups = Object.values(this.props.groups.active).sort(this.nameSorterHelper)
            }
            sortedActiveGroups.forEach(group => {
                options.push(<Option key={group.name} value={group.name} name={group.name}>{group.name}</Option>)
            })
        }

        const sortedAllGroups = this.props.users['all'][record.id].groups !== undefined ?
            this.props.users['all'][record.id].groups.sort(this.nameSorterHelper) : []

        return <Select
            mode="multiple"
            style={{width: '100%'}}
            placeholder="Select groups"
            value={sortedAllGroups.map((obj) => this.props.groups['all'] ? this.props.groups['all'][obj.id].name : obj.name)}
            optionFilterProp='name'
            onSelect={(e) => this.handleGroupAdd(e, record)}
            onDeselect={(e) => this.handleGroupDelete(e, record)}
        >
            {options}
        </Select>
    }

    nameSorterHelper = (a, b) => {
        if (a) {
            return b ? String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase()) : -1
        } else if (b) {
            return a ? String(b.name).toLowerCase().localeCompare(String(a.name).toLowerCase()) : 1
        } else {
            return -1
        }
    }
    sorterHelper = (a, b) => {
        if (a) {
            return b ? a.localeCompare(b) : -1
        } else if (b) {
            return a ? b.localeCompare(a) : 1
        } else {
            return -1
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),

        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) : '',

        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },

        render: text => (
            (this.state.searchedColumn === dataIndex && text) ?
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {

        if (!this.state.modal.arg_two) {
            this.state.modal.action(this.props.token, this.state.modal.arg_one)
        } else {
            this.state.modal.action(this.props.token, this.state.modal.arg_one, this.state.modal.arg_two)
        }
        this.setState({
            visible: false,
            modal: {
                title: null,
                action: null,
                message: null,
                arg_one: null,
                arg_two: null,
                mouseX: null,
                mouseY: null,
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            modal: {
                title: null,
                action: null,
                message: null,
                arg_one: null,
                arg_two: null,
                mouseX: null,
                mouseY: null,
            }
        });
    };


    onCheckChange(record) {
        this.props.updateUserRequest(this.props.token, {id: record.id, active: !record.active})
    }

    handleRoleChange(record) {
        this.props.updateUserRequest(this.props.token, {id: record.id, admin: !record.admin})
    }

    handleGroupAdd(e, record) {
        const targetGroup = _.map(this.props.groups.all, (data => data)).find((group) => {
            return group.name === e
        });

        this.props.addUserToGroup(this.props.token, record.id, targetGroup.id);
    }

    handleGroupDelete(e, record) {
        const targetGroup = _.map(this.props.groups.all, (data => data)).find((group) => {
            return group.name === e
        })

        this.props.removeUserFromGroup(this.props.token, record.id, targetGroup.id);
    }

    componentDidMount() {
        let promises = [
            this.props.getAllGroups(this.props.token),
            this.props.getAllUsers(this.props.token),
        ]
        Promise.all(promises).then(value => this.setState({loading: false}))
    }

    render() {
        const {Title} = Typography

        return <>
            <Typography>
                <Title level={3}> <UserOutlined/> Users </Title>
            </Typography>
            <Divider/>
            {this.state.loading
                ? <Spin/>
                : <div>
                    <Modal open={this.state.visible}
                           onOk={this.handleOk}
                           onCancel={this.handleCancel}
                           style={{
                               maxWidth: 180,
                               minWidth: 180,
                               display: 'flex',
                               position: 'absolute',
                               left: (this.state.modal.mouseX + 90 < window.innerWidth) ? this.state.modal.mouseX - 90 : window.innerWidth - 180,
                               top: (this.state.modal.mouseY + 180 < window.innerHeight) ? this.state.modal.mouseY : window.innerHeight - 180
                           }}
                           mask={false}
                           closable={false}
                           destroyOnClose={true}
                    >
                        {this.state.modal.message}
                    </Modal>
                    <Table rowKey={'id'}
                           columns={this.columns}
                           dataSource={_.map(this.props.users.all, (data => data))}
                    />
                </div>
            }
        </>
    }
}

const mapStateToProps = ({users, groups}) => {
    return {
        users,
        groups
    }
}

export default connect(mapStateToProps, {
    getAllUsers,
    getAllGroups,
    removeUserFromGroup,
    addUserToGroup,
    setUsers,
    updateUserRequest,
    deleteUserRequest,
})(User)