import {notification} from "antd";
import {ERROR_COLOR} from "../constants";

export function handleError(error) {
    switch(error.response.status) {
        case 400:
            notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${error.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
            break

        case 401:
            notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
            break

        case 403:
            notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })
            break

        case 404:
            notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${error.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
            break

        case 409:
            notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${error.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
            break

        case 429:
            notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
            break

        default:
            console.log(error.response.status)
    }
}