import React from 'react';
import { createRoot } from 'react-dom/client';
import { load } from "redux-localstorage-simple";

import App from './App';
import './index.css';

// This automatically adds the JWT to HTMX requests.
document.body.addEventListener('htmx:configRequest', function(event) {
    event.detail.headers['Authorization'] = "Bearer " + load({states: ['token']}).token.token;
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
