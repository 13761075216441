import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Helmet } from 'react-helmet';
import { save, load } from "redux-localstorage-simple";
import reducer from './features/reducer';

import dfci_small from './assets/dfci_small.png';
import { MainAppContainer } from './shared/components';
import './shared/styles/main.scss';

const createMiddleware = applyMiddleware(thunk, save({states: ['token']}))(createStore);
const store = createMiddleware(reducer, load({states: ['token']}));

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Proteomics Dashboard</title>
                    <link rel="shortcut icon" id="favicon" href={dfci_small}></link>
                </Helmet>

                <MainAppContainer />
            </Provider>
        )
    }
}

export default App;