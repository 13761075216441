import { produce } from 'immer'
import {
    SET_USERS,
    UPDATE_USER,
    SET_ACTIVE_USERS,
    UPDATE_ACTIVE_USER,
    REMOVE_ACTIVE_USER,
    REMOVE_USER,
} from './usersConstants'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USERS:
            const { users } = action
            draft['all'] = users
            break
        
        case UPDATE_USER:
            const { userId, data } = action
            draft['all'][userId] = data
            break

        case SET_ACTIVE_USERS:
            const { activeUsers } = action
            draft['active'] =  activeUsers
            break
        
        case UPDATE_ACTIVE_USER:
            const { activeUserId, activeData } = action
            draft['active'][activeUserId] = activeData
            break

        case REMOVE_ACTIVE_USER:
            const { removeUserId } = action
            delete draft['active'][removeUserId]
            break

        case REMOVE_USER:
                const { delUserId } = action
                delete draft['all'][delUserId]
                break
     
        default:
            break
        }
    

    return draft
})