import React from 'react';
import {Menu, MenuProps} from 'antd';
import {Link} from 'react-router-dom';
import {
    DotChartOutlined,
    HeatMapOutlined,
    HomeOutlined,
    LoginOutlined, LogoutOutlined,
    SearchOutlined,
    SettingOutlined,
    TeamOutlined,
    ToolOutlined,
    UserOutlined
} from "@ant-design/icons";
import decode from 'jwt-claims';
import {connect} from "react-redux";

import dfci_small from '../../assets/dfci_small.png';
import {USERS_URL, EXPERIMENTS_URL, GROUPS_URL, HOME_URL, CONFIGURATION_URL, HEATMAP_URL} from '../constants';
import { setToken, toggleLoginModal, logout} from "../../features/token/tokenActions";

function Navbar({token, setToken, toggleLoginModal, logout}) {
    const onLoginClick: MenuProps['onClick'] = (e) => {
        if (!token) {
            toggleLoginModal()
        } else {
            try {
                logout(token)
            } finally {
                setToken(null)
            }
        }
    };

    const mainMenuItems = [{
        key: 'logo',
        label: <Link to={HOME_URL}>DFCI Proteomics</Link>,
        icon: <img style={{height: 'auto', width: 15, margin: 'auto'}} src={dfci_small} alt=''/>
    }, {
        key: 'home',
        label: 'Home',
        icon: <HomeOutlined/>,
        disabled: !token,
        theme: 'light',
        children: [{
            key: '/search/',
            label: <Link to={HOME_URL}>Search</Link>,
            icon: <SearchOutlined/>
        }, {
            key: HEATMAP_URL,
            label: <Link to={HEATMAP_URL}>Heatmap</Link>,
            icon: <HeatMapOutlined/>
        }]
    }, {
        key: 'admin',
        label: 'Admin',
        icon: <ToolOutlined/>,
        disabled: !token,
        theme: 'light',
        children: [{
            key: 'experiments',
            label: <Link to={EXPERIMENTS_URL}>Experiments</Link>,
            icon: <DotChartOutlined/>
        }, {
            key: 'configuration',
            label: <Link to={CONFIGURATION_URL}>Configuration</Link>,
            icon: <SettingOutlined/>
        }, {
            key: 'users',
            label: <Link to={USERS_URL}>Users</Link>,
            icon: <UserOutlined/>
        }, {
            key: 'groups',
            label: <Link to={GROUPS_URL}>Groups</Link>,
            icon: <TeamOutlined/>
        }]
    }, {
        key: 'login',
        label: !token ? 'Login' : 'Logout',
        icon: !token ? <LoginOutlined/> : <LogoutOutlined />,
        onClick: onLoginClick
    }];

    return (
        <>
            <Menu style={{backgroundColor: 'rgb(63, 63, 63)', lineHeight: '64px'}}
                  theme="dark"
                  mode="horizontal"
                //openKeys={['adminMenu', 'mainMenu']}
                  selectable={false}
                  items={mainMenuItems}
            />
        </>
    );
}

function mapStateToProps({ token }) {
    return {
        token: token.token
    };
}

export default connect(mapStateToProps, {
    setToken,
    toggleLoginModal,
    logout
})(Navbar);
