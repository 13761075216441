import React, {useState, useEffect} from "react";


export default function SMILESChemicalStructure({ chemicalStructureSVG }) {
    const [_chemicalStructureSVG, setChemicalStructureSVG] = useState('');

    useEffect(() => {
        setChemicalStructureSVG(chemicalStructureSVG);
    }, [chemicalStructureSVG])

    return (
        <img style={{maxHeight: '450px', height: '95%'}} className='chemical-structure-svg' src={`data:image/svg+xml;utf8,${encodeURIComponent(_chemicalStructureSVG)}`}/>
    )
}