import React, { Component } from 'react'
import { Typography, Divider } from 'antd'
import { MAILTO, ADMIN_EMAIL, HOME_URL } from '../shared/constants'
import decode from 'jwt-claims'
import {WarningTwoTone} from "@ant-design/icons";

class Inactive extends Component {
    constructor(props) {
      super(props);
      this.state = {  
          identity: null,
          active: false,
      }
    }

    componentDidMount() {
        if (this.props.token) {
          const { identity, user_claims } = decode(this.props.token)
          const { active } = user_claims
          this.setState({ identity, active })

          if ( active ) {
            window.location.href = HOME_URL
          }
        } 
    }

    render() {
        const { Title } = Typography

        return <>
          <Typography>
            <Title level={3}><WarningTwoTone /> Inactive User</Title>
            <Divider />
            {this.state.active 
            ?<Typography.Paragraph>
              Hi {this.state.identity},
              You are not an inactive user.
            </Typography.Paragraph>
            :<Typography.Paragraph>
              You are an inactive user. Please email {<a href={MAILTO}>{ADMIN_EMAIL}</a>} for access to the application.
            </Typography.Paragraph>
            }
          </Typography>
        </>
    }
}

export default Inactive;