import { SET_EXPERIMENTS, UPDATE_EXPERIMENT, SET_ACTIVE_EXPERIMENTS, UPDATE_ACTIVE_EXPERIMENT, REMOVE_ACTIVE_EXPERIMENT, SET_SEARCHED_EXPERIMENT, GET_ALL_COMPOUNDS, GET_ALL_PROTEINS, GET_EXPERIMENT_COMPOUNDS } from './experimentsConstants'
import ApiService from '../apiService'

export const setAllCompounds = (allCompounds) => {
    return {
        type: GET_ALL_COMPOUNDS,
        allCompounds
    }
}

export const setExperimentCompounds = (experimentCompounds) => {
    return {
        type: GET_EXPERIMENT_COMPOUNDS,
        experimentCompounds
    }
}

export const setAllProteins = (allProteins) => {
    return {
        type: GET_ALL_PROTEINS,
        allProteins
    }
}

export const setSearchedExperiment = (searchedExperiment) => {
    return {
        type: SET_SEARCHED_EXPERIMENT,
        searchedExperiment,
    }
}

export const setExperiments	 = (experiments) => {
    return {
        type: SET_EXPERIMENTS,
        experiments,
    }
}

export const updateExperiment = (experimentId, data) => {
    return {
        type: UPDATE_EXPERIMENT,
        experimentId,
        data,
    }
}

export const setActiveExperiments = (activeExperiments) => {
    return {
        type: SET_ACTIVE_EXPERIMENTS,
        activeExperiments,
    }
}

export const updateActiveExperiment = (activeExperimentId, activeData) => {
    return {
        type: UPDATE_ACTIVE_EXPERIMENT,
        activeExperimentId,
        activeData,
    }
}

export const removeActiveExperiment = (removeExperimentId) => {
    return {
        type: REMOVE_ACTIVE_EXPERIMENT,
        removeExperimentId,
    }
}

export const getAllExperiments = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getExperiments(token)
        const experiments = {}
        const active = {}

        if (getResponse) {
            getResponse.data.forEach(element => {
                experiments[ element.id ] = element
                if (element.active) {
                    active[ element.id ] = element
                }
            })
        }
        dispatch(setExperiments(experiments))
        dispatch(setActiveExperiments(active))

    } catch(error) {
        console.warn(error)
    }
}

export const updateExperimentRequest = (token, data) => async dispatch => {
    try {
        const updateResponse = await ApiService.updateExperiment(token, data)
        if (updateResponse) {
            dispatch(updateExperiment(updateResponse.data.id, updateResponse.data))

            if (data.active || updateResponse.data.active) {
                dispatch(updateActiveExperiment(updateResponse.data.id, updateResponse.data))
            } else if ( data.active === false ) {
                dispatch(removeActiveExperiment(updateResponse.data.id))
            }
        }
        return updateResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const createExperimentRequest = (token, data) => async dispatch => {
    try {
        const createResponse = await ApiService.createExperiment(token, data)
        if (createResponse) {
            dispatch(updateExperiment(createResponse.data.id, createResponse.data))
            dispatch(updateActiveExperiment(createResponse.data.id, createResponse.data))
        }
        return createResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const bulkCreateExperimentRequest = (token, data) => async dispatch => {
    try {
        const createResponse = await ApiService.bulkCreateExperiment(token, data)
        dispatch(getAllExperiments(token))
        return createResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const getSingleExperimentRequest = (token, experimentId) => async dispatch => {
    try {
        const getResponse = await ApiService.getSingleExperiment(token, experimentId)
        return getResponse.data;

    } catch(error) {
        console.warn(error)
    }
}

export const getResultDataRequest = ( token, queryId, queryType ) => async dispatch => {
    try {
        const getResponse = await ApiService.getResultData(token, queryId, queryType)
        dispatch(setSearchedExperiment(getResponse.data))
        return getResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const getExperimentResultDataRequest = ( token, queryId, queryType, experimentId, updateState=true, extra='') => async dispatch => {
    try {
        const getResponse = await ApiService.getExperimentResultData(token, queryId, queryType, experimentId, extra)
        if (updateState) { dispatch(setSearchedExperiment(getResponse.data)) }
        return getResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const getHeatmapDataRequest = ( token, experimentId, accessions ) => async dispatch => {
    try {
        const getResponse = await ApiService.getExperimentResultData(token, accessions[0], 'protein', experimentId, `?foo=[${accessions.toString()}]`);
        return getResponse.data;
    } catch(error) {
        console.warn(error);
    }
}

export const getAllCompounds = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getCompounds(token);
        if (getResponse) {
            dispatch(setAllCompounds(getResponse.data));
        }
    } catch(error) {
        console.warn(error);
    }
}
export const getExperimentCompounds = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getExperimentCompounds(token);
        if (getResponse) {
            dispatch(setExperimentCompounds(getResponse.data));
        }
    } catch(error) {
        console.warn(error);
    }
}

export const getAllProteins = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getProteins(token);
        if (getResponse) {
            dispatch(setAllProteins(getResponse.data));
        }
    } catch(error) {
        console.warn(error);
    }
}

export const deleteExperimentRequest = (token, experimentId) => async dispatch => {
    try {
        const deleteResponse = await ApiService.deleteExperiment(token, experimentId);
        dispatch(getAllExperiments(token));
        return deleteResponse.data;
    } catch(error) {
        console.warn(error);
    }
}