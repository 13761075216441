import { SET_TOKEN, TOGGLE_LOGIN_MODAL } from './tokenConstants'
import ApiService from '../apiService'

export const setToken = (token) => {
    return {
        type: SET_TOKEN	,
        token,
    }
}

export const logout = (token) => async dispatch => {
    try {
        const logout = await ApiService.logout(token)
        return logout.data
    } catch(error) {
        console.warn(error)
    }
}


export const toggleLoginModal = () => {
    return {
        type: TOGGLE_LOGIN_MODAL
    }
}