import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter } from "react-router-dom";

import {ContentArea, Navbar} from '../components';

const { Header, Content, Footer } = Layout


export default function MainAppContainer() {
    return (
        <BrowserRouter>
            <Layout className="layout">
              <Header style={{backgroundColor: 'rgb(63, 63, 63)'}}>
                <Navbar />
              </Header>

              <Content style={{ padding: '0 50px' }}>
                  <ContentArea />
              </Content>

                <Footer style={{textAlign: 'center'}}>
                    ©2024 Dana-Farber Cancer Institute
                </Footer>
            </Layout>
        </BrowserRouter>
    );
}
