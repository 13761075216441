import React, {Component} from 'react'
import {Typography, Divider, Select, Button, Col, Row, Spin, Slider, InputNumber} from 'antd'
import {connect} from 'react-redux'
import {
    addCustomCompound,
    addCustomProtein,
    removeCustomCompound,
    removeCustomProtein,
    setCompoundSearch,
    setProteinSearch,
    clusterHeatmapData,
    clearHeatmapData,
} from '../features/results/resultsActions'
import {
    getExperimentCompounds,
    getAllProteins,
    setSearchedExperiment,
} from '../features/experiments/experimentsActions'
import Plot from 'react-plotly.js'
import _ from 'lodash'
import {SMILESChemicalStructure} from "../shared/components";
import {ArrowUpOutlined, CloseOutlined, HeatMapOutlined} from "@ant-design/icons";

const {Option} = Select

class CustomHeatmap extends Component {
    state = {
        loading: false,
        heatmapColors: [
            'Greys', 'YlGnBu', 'Greens', 'YlOrRd',
            'Bluered', 'RdBu', 'Reds', 'Blues', 'Picnic',
            'Rainbow', 'Portland', 'Jet', 'Hot', 'Blackbody',
            'Earth', 'Electric', 'Viridis', 'Cividis'
        ],
        heatmapColorSelected: 'RdBu',
        heatmapMin: null,
        heatmapMax: null,
        chemicalStructures: []
    }

    componentDidMount() {
        const {
            getAllProteins,
            getExperimentCompounds,
            token,
            setCompoundSearch,
            setProteinSearch,
            setSearchedExperiment
        } = this.props
        setSearchedExperiment(null)

        if (token) {
            getAllProteins(token)
            getExperimentCompounds(token)
        }
        document.body.addEventListener('click', (e) => setTimeout(() => { // hack hack hack hack hack
            setCompoundSearch('')
            setProteinSearch('')
        }, 100))

    }

    getHeatmapStyleOptions() {
        let options = []
        const {heatmapColors} = this.state

        heatmapColors.forEach(element => {
            options.push(<Option key={element} value={element}>{element}</Option>)
        })
        return options
    }

    groupListByN = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
        return result;
    };

    selectSorterHelper = (a, b) => {
        if (a) {
            return b ? String(a.id).toLowerCase().localeCompare(String(b.id).toLowerCase()) : -1
        } else if (b) {
            return a ? String(b.id).toLowerCase().localeCompare(String(a.id).toLowerCase()) : 1
        } else {
            return -1
        }
    }

    nameSorterHelper = (a, b) => {
        if (a) {
            return b ? String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase()) : -1
        } else if (b) {
            return a ? String(b.name).toLowerCase().localeCompare(String(a.name).toLowerCase()) : 1
        } else {
            return -1
        }
    }

    compoundSorterHelper = (a, b) => {
        if (a) {
            return b ? String(`${a.treatment_name} (${a.experiment_name})`).toLowerCase().localeCompare(String(`${b.treatment_name} (${b.experiment_name})`).toLowerCase()) : -1
        } else if (b) {
            return a ? String(`${b.treatment_name} (${b.experiment_name})`).toLowerCase().localeCompare(String(`${a.treatment_name} (${a.experiment_name})`).toLowerCase()) : 1
        } else {
            return -1
        }
    }

    render() {
        const {Title, Text} = Typography
        const {Option} = Select
        const proteinOptions = []
        const compoundOptions = []
        const {
            experiments,
            results,
            addCustomCompound,
            removeCustomCompound,
            setCompoundSearch,
            setProteinSearch,
            addCustomProtein,
            removeCustomProtein,
            clusterHeatmapData,
            token,
            clearHeatmapData,
        } = this.props
        const {heatmapColorSelected, heatmapMin, heatmapMax} = this.state
        const {allProteins, experimentCompounds} = experiments
        const {customProtein, customCompound, compoundSearch, proteinSearch, customHeatmapData} = results
        const showCompounds = experimentCompounds && compoundSearch && compoundSearch.length > 2
        const showProteins = allProteins && proteinSearch && proteinSearch.length > 2

        if (_.isEmpty(experimentCompounds) || _.isEmpty(allProteins)) {
            return <Spin/>
        }

        if (showCompounds) {
            Object.values(experimentCompounds).sort(this.compoundSorterHelper).forEach(compound => {
                compoundOptions.push(<Option key={`${compound.treatment_id}-${compound.experiment_id}`}
                                             name={`${compound.treatment_name} (${compound.experiment_name})`}>{`${compound.treatment_name} (${compound.experiment_name})`}</Option>)
            })
        }
        if (showProteins) {
            Object.values(allProteins).sort(this.nameSorterHelper).forEach(protein => {
                if (`${protein.name} (${protein.gene_symbol})`.toLowerCase().includes(proteinSearch.toLowerCase())) {
                    proteinOptions.push(<Option key={protein.accession_id}
                                                name={`${protein.name} (${protein.gene_symbol})`}>{`${protein.name} (${protein.gene_symbol})`}</Option>)
                }
            })
        }

        const data = customHeatmapData
        const zVals = data && data.heatmap_len > 0 ? _.map(data['heatmap_data'], obj => obj.slice(0, data['heatmap_len'])) : []
        const xVals = data && data.heatmap_len > 0 ? data['heatmap_columns'].slice(0, data['heatmap_len']) : []
        const yVals = data && data.heatmap_len > 0 ? _.map(data['heatmap_data'], obj => `${obj.slice(-2)[0].split(';')[0]} (${obj.slice(-1)[0]})`) : []
        let customData = data && data.heatmap_len > 0 ? _.map(data['heatmap_data'], obj => {
            let finalData = []
            const rawData = obj.slice(data['heatmap_len'], -2)
            const transformData = this.groupListByN(data['heatmap_len'], rawData)

            for (let i = 0; i < data['heatmap_len']; i++) {
                finalData.push([
                    !_.isNil(transformData[0][i]) ? transformData[0][i] : 'NOT FOUND', !_.isNil(transformData[1][i]) ? transformData[1][i] : 'NOT FOUND',
                    !_.isNil(transformData[2][i]) ? transformData[2][i] : 'NOT FOUND', !_.isNil(transformData[3][i]) ? transformData[3][i] : 'NOT FOUND',
                    xVals[i].split(' - ')[0],
                    !_.isNil(transformData[4][i]) ? transformData[4][i] : 'NOT FOUND'
                ]);
            }
            return finalData
        }) : []

        const maxVals = _.max(_.map(zVals, (obj) => _.max(obj)))
        const minVals = _.min(_.map(zVals, (obj) => _.min(obj)))
        const allVals = _.concat(maxVals, minVals)
        const scale = Math.abs(_.maxBy(allVals, obj => Math.abs(obj)))

        const heatmapData = [
            {
                type: 'heatmap',
                autocolorscale: false,
                colorscale: heatmapColorSelected,
                zauto: false,
                zmax: heatmapMax ? heatmapMax : scale,
                zmin: heatmapMin ? heatmapMin : -scale,
                x: xVals,
                y: yVals,
                z: zVals,
                hoverongaps: false,
                name: '',
                customdata: customData,
                hovertemplate: `
<b>Log<sub>2</sub>FC:</b> %{z}<br />
<b>Log<sub>10</sub>P-Value:</b> %{customdata[0]}<br />
<b>Concentration (&#181;):</b> %{customdata[1]}<br />
<b>Time (seconds):</b> %{customdata[2]}<br />
<b>Cell Line:</b> %{customdata[3]}<br />
<b>Treatment:</b> %{customdata[4]}<br />
<b>Experiment Type:</b> %{customdata[5]}<br />
<b>Accession:</b> %{y}`
            },
        ]
        return <>
            <Typography>
                <Title level={3}><HeatMapOutlined/> Custom Heatmap</Title>
            </Typography>
            <Divider/>
            <div className='align-search'>
                <Col span={11}>
                    <Title level={4}>Treatments</Title>
                    <Select
                        mode="multiple"
                        style={{width: '100%'}}
                        placeholder="Enter treatments (3 character minimum)"
                        optionFilterProp='name'
                        value={customCompound ? _.map(Object.values(customCompound).sort(this.selectSorterHelper), obj => obj.id) : []}
                        onSelect={(e, r) => {
                            addCustomCompound({id: r.props.name, key: e})
                            setCompoundSearch('')
                        }}
                        onDeselect={(e, r) => {
                            removeCustomCompound(r.props.value)
                            setCompoundSearch('')
                        }}
                        onSearch={(e) => setCompoundSearch(e)}
                        dropdownStyle={showCompounds ? {} : {display: 'none'}}
                    >
                        {compoundOptions}
                    </Select>
                </Col>
                <Col span={11}>
                    <Title level={4}>Proteins</Title>
                    <Select
                        mode="multiple"
                        style={{width: '100%'}}
                        placeholder="Enter proteins 3 character minimum)"
                        optionFilterProp='name'
                        value={customProtein ? _.map(Object.values(customProtein).sort(this.selectSorterHelper), obj => obj.id) : []}
                        onSelect={(e, r) => {
                            addCustomProtein({id: r.props.name, key: e})
                            setProteinSearch('')
                        }
                        }
                        onDeselect={(e, r) => {
                            removeCustomProtein(r.props.value)
                            setProteinSearch('')
                        }}
                        onSearch={(e) => setProteinSearch(e)}
                        dropdownStyle={showProteins ? {} : {display: 'none'}}
                    >
                        {proteinOptions}
                    </Select>
                    <Button disabled={_.isEmpty(customProtein) || _.isEmpty(customCompound)}
                            onClick={() => this.setState(
                                {loading: true},
                                () => clusterHeatmapData(token, Object.values(customProtein), Object.values(customCompound)).then(() => {
                                    const {customHeatmapData} = this.props.results;
                                    const data = customHeatmapData;
                                    const zVals = data ? _.map(data['heatmap_data'], obj => obj.slice(0, data['heatmap_len'])) : []
                                    const maxVals = _.max(_.map(zVals, (obj) => _.max(obj)))
                                    const minVals = _.min(_.map(zVals, (obj) => _.min(obj)))
                                    const allVals = _.concat(maxVals, minVals)
                                    const scale = Math.abs(_.maxBy(allVals, obj => Math.abs(obj)))
                                    this.setState({
                                        loading: false,
                                        heatmapMin: -scale,
                                        heatmapMax: scale,
                                        chemicalStructures: data ? data['chemical_structures'] : []
                                    })
                                })
                            )}
                            style={{marginTop: 10, marginBottom: 10, float: 'right'}}
                            type='primary'
                            icon={<ArrowUpOutlined/>}
                    >
                        Submit
                    </Button>
                    <Button style={{marginTop: 10, marginBottom: 10, marginRight: 10, float: 'right'}}
                            icon={<CloseOutlined />}
                            onClick={() => {
                                clearHeatmapData()
                            }}
                    >
                        Clear
                    </Button>
                </Col>
            </div>
            {this.state.loading ? <Spin/> : null}
            {data && !this.state.loading ?
                <div style={{marginBottom: 100}}>
                    <Plot
                        config={{
                            displaylogo: false,
                            responsive: true,
                            toImageButtonOptions: {
                                format: 'svg',
                                filename: 'custom_heatmap'
                            }
                        }
                        }
                        data={heatmapData}
                        layout={{
                            margin: {t: 0, r: 0, l: 20, b: 200},
                            hovermode: 'closest',
                            autosize: true,
                            showlegend: false,
                            xaxis: {
                                title: 'Treatments',
                            },
                            yaxis: {
                                title: {
                                    text: 'Accessions',
                                    standoff: 10,
                                },
                                automargin: true,

                            }
                        }}
                        useResizeHandler={true}
                        style={{width: "100%"}}
                    />
                    <div id={'chemicalStructures'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        alignItems: 'stretch'
                    }}>
                        {!this.state.loading ? Object.entries(this.state.chemicalStructures).map(([key, value]) => (
                            <div key={key} style={{textAlign: 'center'}}>
                                <SMILESChemicalStructure chemicalStructureSVG={value}/>
                                <b>{key}</b>
                            </div>
                        )) : ''}
                    </div>
                    <div className='customControls'>
                        <Row>
                            <span className='customTitle'>Heatmap Colors:</span>
                        </Row>
                        <Row>
                            <Select
                                className='searchDropdown'
                                value={heatmapColorSelected}
                                style={{width: '100%'}}
                                optionFilterProp="children"
                                onChange={val => this.setState({heatmapColorSelected: val})}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {this.getHeatmapStyleOptions()}
                            </Select>
                        </Row>
                        <Row>
                            <span className='customTitle'>Heatmap Range:</span>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <InputNumber
                                    style={{width: '75%', marginLeft: 16}}
                                    min={-10}
                                    max={10}
                                    step={0.1}
                                    value={heatmapMin}
                                    onChange={
                                        value => {
                                            if (!isNaN(value)) {
                                                const rounded = _.round(value ? value : 0, 1)
                                                this.setState({heatmapMin: rounded})
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }
                                    }
                                />
                            </Col>
                            <Col span={8}>
                                <Slider
                                    range={true}
                                    value={[heatmapMin, heatmapMax]}
                                    step={0.1}
                                    min={-10}
                                    max={10}
                                    onChange={value => this.setState({heatmapMin: value[0], heatmapMax: value[1]})}
                                />
                            </Col>
                            <Col span={8}>
                                <InputNumber
                                    style={{width: '75%', marginLeft: 16}}
                                    min={-10}
                                    max={10}
                                    step={0.1}
                                    value={heatmapMax}
                                    onChange={
                                        value => {
                                            if (!isNaN(value)) {
                                                const rounded = _.round(value ? value : 0, 1)
                                                this.setState({heatmapMax: rounded})
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                :
                <Text>Create a <Text strong>Custom Heatmap</Text> by adding the treatments and proteins of interest.
                    Start
                    typing the name of treatments or proteins for autocomplete search.</Text>}
        </>
    }
}

const mapStateToProps = ({experiments, results}) => {
    return {
        experiments,
        results,
    }
}

export default connect(mapStateToProps, {
    addCustomCompound,
    addCustomProtein,
    removeCustomCompound,
    removeCustomProtein,
    getExperimentCompounds,
    getAllProteins,
    setCompoundSearch,
    setProteinSearch,
    clusterHeatmapData,
    setSearchedExperiment,
    clearHeatmapData,
})(CustomHeatmap)